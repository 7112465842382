<template>
  <div class="home section">
    <div class="container home-btns">
      <router-link :to="{ name: 'record_display' }" class="home-btns__item">
        <i class="fas fa-desktop"></i>
      </router-link>
      <router-link :to="{ name: 'record_list' }" class="home-btns__item">
        <i class="fas fa-list-ol"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>
